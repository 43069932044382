<template>
  <div id="apipe-cloud-course-page">
    <top-nav-new />

    <div class="apipe-cloud-course-container">
      <div class="top-banner d-none d-lg-block">
        <div class="container" style="text-align: left">
          <h2>关于我们</h2>
        </div>
      </div>
      <div class="mobile-banner d-block d-lg-none">
        <div class="container" style="">
          <h4>关于我们</h4>
        </div>
      </div>

      <div class="pc-customeValue d-none d-lg-block">
        <div class="top container">
          <div class="main">
            <div class="tit">公司介绍</div>
            <div class="cot">
              深圳优制云工业互联网有限公司，自2018年成立以来，以5500万元注册资本坚实起步深度践行“中国制造2025”等国家战略，成为行业数字化转型的中坚力量。我们植根于10年以上的数字化与管道行业的深厚土壤致力于将先进技术转化为推动行业变革的强大引擎。优制云工业互联秉承“智能赋能未来”的使命，依托先进的工业互联网与大数据平台，精准布局智能制造、数字企业、智慧园区三大关键域，为中小微企业提供全方位、一站式的数字化解决方案，成为企业信赖的数字化转型伙伴。
            </div>
            <div class="data">
              <div>
                <span class="num">5500万</span>
                <div class="des">注册资金</div>
              </div>
              <div>
                <span class="num">10年+</span>
                <div class="des">数字化实践</div>
              </div>
              <div>
                <span class="num">40+</span>
                <div class="des">专利&软著</div>
              </div>
            </div>
            <div class="cj">
              <div class="wrap">
                <img
                  src="@/assets/newSite/aboutus/a1.png"
                  style="height: 54px; width: 54px"
                  alt=""
                />
                <div class="de">中国软件企业</div>
              </div>
              <div class="wrap">
                <img
                  src="@/assets/newSite/aboutus/a2.png"
                  style="height: 54px; width: 54px"
                  alt=""
                />
                <div class="de">国家高新技术企业</div>
              </div>
            </div>
          </div>
        </div>
        <div class="fzlc">
          <div class="tit">发展历程</div>
          <img src="@/assets/newSite/aboutus/ex.png" alt="" style="width: -webkit-fill-available;" />
        </div>
        <div class="container">
          <h2 style="padding:60px 0 20px 0;">联系我们</h2>
          <div class="main-cot">
            
            <div>
              <img class="img" src="@/assets/newSite/aboutus/map.png" alt="" />
            </div>
            <div>
              <div>
                <div style="display:flex;">
                  <img src="@/assets/newSite/aboutus/location.png" width="36px" height="36px" alt="" />
                  <h2 style="margin-left:10px;">公司地址</h2>                  
                </div>

                <div style="padding-left:50px;">深圳市南山区朗山路16号华瀚创新园A座10楼</div>
              </div>
              <div style="margin: 30px 0">
                <div style="display:flex;">
                  <img src="@/assets/newSite/aboutus/tel.png" width="36px" height="36px" alt="" />
                  <h2 style="margin-left:10px;">服务热线</h2>
              </div>
                <div style="padding-left:50px;">0755-86016600/13808805396</div>
              </div>
              <div>
                <div style="display:flex;">
                <img src="@/assets/newSite/aboutus/email.png" width="36px" height="36px" alt="" />
                <h2 style="margin-left:10px;">邮箱</h2>
              </div>
                <div style="padding-left:50px;">cs@veiban.com</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mobile-customeValue d-block d-lg-none">
        <div class="container">
          <div class="main-cot">
            <p>
              深圳优制云工业互联网有限公司，自2018年成立以来，以5500万元注册资本坚实起步深度践行“中国制造2025”等国家战略，成为行业数字化转型的中坚力量。我们植根于10年以上的数字化与管道行业的深厚土壤致力于将先进技术转化为推动行业变革的强大引擎。优制云工业互联秉承“智能赋能未来”的使命，依托先进的工业互联网与大数据平台，精准布局智能制造、数字企业、智慧园区三大关键域，为中小微企业提供全方位、一站式的数字化解决方案，成为企业信赖的数字化转型伙伴。
            </p>

            <div class="data">
              <div>
                <span class="num">5500万</span>
                <div class="des">注册资金</div>
              </div>
              <div>
                <span class="num">10年+</span>
                <div class="des">数字化实践</div>
              </div>
              <div>
                <span class="num">40+</span>
                <div class="des">专利&软著</div>
              </div>
            </div>

            <div class="cj">
              <div class="wrap">
                <img
                  src="@/assets/newSite/aboutus/s1.png"
                  style="height: 54px; width: 54px"
                  alt=""
                />
                <div class="de">中国软件企业</div>
              </div>
              <div class="wrap">
                <img
                  src="@/assets/newSite/aboutus/s2.png"
                  style="height: 54px; width: 54px"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div class="way" style="margin-bottom: 30px">
             <div class="tit">发展历程</div>
            <img src="@/assets/newSite/aboutus/ways.png" alt="" />
        </div>

        <div class="map" style="margin-bottom: 30px">
           <div class="tit">联系我们</div>
            <img src="@/assets/newSite/aboutus/map.png" alt="" />
        </div>

        <div class="site">
          <div>
              <div>
                <div style="display:flex;align-items:center">
                  <img src="@/assets/newSite/aboutus/location.png" width="36px" height="36px" alt="" />
                  <div>
                    <div style="margin-left:15px;font-size:20px;">公司地址</div>                  
                    <div style="padding-left:15px;font-size:14px;">深圳市南山区朗山路16号华瀚创新园A座10楼</div>
                  </div>
                </div>
              </div>

              <div style="margin:30px 0;">
                <div style="display:flex;align-items:center">
                  <img src="@/assets/newSite/aboutus/tel.png" width="36px" height="36px" alt="" />
                  <div>
                    <div style="margin-left:15px;font-size:20px;">服务热线</div>                  
                    <div style="padding-left:15px;font-size:14px;">0755-86016600/13808805396</div>
                  </div>
                </div>
              </div>

              <div>
                <div style="display:flex;align-items:center">
                  <img src="@/assets/newSite/aboutus/email.png" width="36px" height="36px" alt="" />
                  <div>
                    <div style="margin-left:15px;font-size:20px;">邮箱</div>                  
                    <div style="padding-left:15px;font-size:14px;">cs@veiban.com</div>
                  </div>
                </div>
              </div>
 
            </div>
          </div>

      </div>

      <NewFooter @handleClickChild="handleClickChild" />
      <MobileFooter  ref="mofooter" class="d-block d-lg-none" />
    </div>
  </div>
</template>
            
<script>
export default {
  name: "hardware",
  metaInfo() {
    return {
      title: '关于我们 - 优制云工业互联网平台',
      meta: [
        { name: 'keywords', content: '工业互联网,智能制造,工业数字化,工业SaaS系统,优制云,伟班平台,伟班APP' },
        { name: 'description', content: '深圳优制云工业互联网公司，专为中小微企业提供基于伟班平台的智能制造、数智办公及智慧园区一站式数字化解决方案与服务，助力企业高效转型，迈向智能化未来。' },
        
      ],
    };
  },
  data() {
    return {
      cur: 1,
      activeName: "1",
    };
  },
 
  mounted() {
    const type = this.$route.query.type || ''
    this.getScroll(type)
  },

  methods: {
    handleClickChild(){
     this.$refs.mofooter.handleDeal(2)
    },
     getScroll(type){
      console.log(type)
      if(type == 's'){
        document.documentElement.scrollTop = 500
      }else if(type == 'f'){
        document.documentElement.scrollTop = 1300
      }else if(type == 'l'){
        document.documentElement.scrollTop = 2000
      }else{
        // document.documentElement.scrollTop = 0
      }
     }
  },
};
</script>
            
<style lang="less"  scoped>
.apipe-cloud-course-container {
  .top-banner {
    height: 600px;
    background-image: url("../../assets/newSite/aboutus/banner.png");
    background-size: cover;
    background-repeat: no-repeat;
    .container {
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      .tit {
        font-size: 16px;
      }
    }
  }
  .mobile-banner {
    height: 200px;
    background-image: url("../../assets/newSite/home/p4.png");
    background-size: cover;
    background-repeat: no-repeat;
    .container {
      color: white;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      .tit {
        font-size: 12px;
      }
      .exp {
        margin-top: 0.5rem;
        font-size: 12px;
        border: 1px solid white;
        display: inline-block;
        width: 80px;
        text-align: center;
      }
    }
  }

  .pc-customeValue {
    padding: 60px 0;
    .top {
      background-image: url("../../assets/newSite/aboutus/bg1.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      height: 730px;
      position: relative;
      .main {
        text-align: left;
        position: absolute;
        right: 120px;
        top: 120px;
        width: 650px;
        height: 400px;
        // border: 1px solid red;
        .tit {
          margin-bottom: 25px;
          line-height: 40px;
          font-family: Source Han Sans CN;
          color: #000000;
          font-size: 40px;
        }
        .cot {
          line-height: 30px;
          font-family: Source Han Sans CN;
          font-weight: 200;
          color: #333333;
          font-size: 16px;
        }
        .data {
          display: flex;
          justify-content: space-around;
          margin-top: 50px;

          .num {
            font-family: Source Han Sans CN;
            font-weight: 700;
            color: #1484ff;
            font-size: 42px;
          }
          .des {
            text-align: center;
            font-family: Source Han Sans CN;
            font-weight: 200;
            color: #333333;
            font-size: 18px;
          }
        }
        .cj {
          display: flex;
          justify-content: space-evenly;
          margin-top: 30px;
          text-align: center;
          .wrap {
            text-align: center;
          }
          .de {
            margin-top: 10px;
          }
        }
      }
    }
    .fzlc {
      padding: 60px 0;
      background:#f6faff;
      .tit{
     margin-bottom: 30px;
      font-family: Source Han Sans CN;
      color: #333333;
      font-size: 36px;
  
      }
    }

    .main-cot {
      margin: 20px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: left;
      .img {
        width: 800px;
        height: 460px;
      }
    }
  }
  .mobile-customeValue {
    .main-cot {
      padding: 20px 10px;
      text-align: left;
      .data{
        display: flex;
        >div{
          flex: 1;
          text-align: center;
          background: #f6faff;
          height: 100px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin: 0 10px;
          .num{
            font-size: 24px;
            color: #1484ff;
            font-weight: 700;
          }
          .des{
            font-size: 15px;
            color: #333333;;
          }
        }
      }
      .cj{
        display: flex;
        margin-top: 10px;
        .wrap{
          flex: 1;
          text-align: center;
          background: #f6faff;
          padding: 10px 0;
          margin: 0 10px;
          .de{
            font-size: 12px;
            color: #1484ff;
            font-weight: 200;
          }
        }
      }
    }
    .way{
       padding: 30px 0;
       background: #f6faff;
       margin-top: 30px;
       .tit{
        font-family: Source Han Sans CN;
        font-weight: 700;
        color: #333333;
        font-size: 20px;
       }
      }
      .map{
        padding: 0 20px;
        img{
          width: 100%;
        }
        .tit{
        font-family: Source Han Sans CN;
        font-weight: 700;
        color: #333333;
        font-size: 20px;
        margin-bottom: 15px;
       }
      }
      .site{
        padding: 25px;
        text-align: left;
      }
  }
}
</style>
            